export default [
  {
    key: 'oldPassword',
    placeholder: 'placeholder.password',
    label: 'field.oldPassword',
    rules: 'required',
    type: 'password',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'newPassword',
    placeholder: 'placeholder.password',
    label: 'field.newPassword',
    rules: 'required|min:6',
    type: 'password',
    cols: 6,
  },
  {
    key: 'retypeNewPassword',
    label: 'field.retypeNewPassword',
    placeholder: 'placeholder.password',
    rules: 'required|confirmed:newPassword',
    type: 'password',
    cols: 6,
  },
]
