export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
    disabled: true,
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
  },
]
